import { useCookies } from "react-cookie";
import { DiscussionEmbed } from "disqus-react";
import { graphql, StaticQuery, PageProps } from "gatsby";
import { Column, Row } from "hedron";
import React from "react";
import ReactHtmlParser from "react-html-parser";
import ArticleMeta from "../components/ArticleMeta";
import Layout from "../components/Layout";
import PageContent from "../components/PageContent";
import SEO from "../components/seo";
import SharingBox from "../components/SharingBox";
import SideBar from "../components/SideBar";
import styled from "../lib/styled";
import useAxios from "../lib/use-axios";
import { IWordpressNodeRest } from ".";
import qs from "qs";
import moment from "moment";
import ContentParser from "../components/ContentParser";

interface IAllWordpressAcfOptions {
  edges: IWordpressAcfOptions[];
}
interface IWordpressAcfOptions {
  node: {
    options: {
      site_404_page_text: string;
      site_404_page_header: string;
    };
  };
}
interface FeaturedMediaResponse {
  media_details: {
    sizes: {
      full: {
        source_url: string;
      };
    };
  };
}

interface IData {
  allWordpressAcfOptions: IAllWordpressAcfOptions;
}
interface IProps {
  data: IData;
  location: PageProps["location"];
}

interface AuthResponse {
  token: string;
}

const PreviewPage = ({ location }: IProps) => {
  const [pageData, setPageData] = React.useState<IWordpressNodeRest>();
  const [token, setToken] = React.useState<string>();
  const [featuredMedia, setFeaturedMedia] = React.useState<
    FeaturedMediaResponse
  >();
  const wpRestEndpoint =
    "https://controlroom.jurassicoutpost.com/wp-json/outpost/v1";
  const [cookies, setCookie] = useCookies(["access"]);

  const [response, fetchPost] = useAxios<IWordpressNodeRest>((id) => ({
    url: `${wpRestEndpoint}/preview/${id}?_embed=true`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
  }));

  const [authResponse, requestAuth] = useAxios<AuthResponse>((auth) => ({
    url: `https://controlroom.jurassicoutpost.com/wp-json/jwt-auth/v1/token`,
    method: "POST",
    data: {
      username: auth.username,
      password: auth.password,
    },
  }));

  const collectCredentials = () => {
    let username = "";
    let password = "";
    username = window.prompt("Enter your username");
    if (username) {
      password = window.prompt("Enter your password");
    }
    if (username && password) {
      requestAuth({ username, password });
    }
  };
  React.useEffect(() => {
    if (cookies && cookies.access) {
      setToken(cookies.access);
    } else if (window) {
      collectCredentials();
    }
  }, []);

  React.useEffect(() => {
    if (authResponse && authResponse.data) {
      setCookie("access", authResponse.data.token, {
        expires: moment().add(7, "days").toDate(),
      });
      setToken(authResponse.data.token);
    }
  }, [authResponse]);

  const [featuredMediaData, fetchFeaturedMedia] = useAxios<
    FeaturedMediaResponse
  >((id) => ({
    url: `https://controlroom.jurassicoutpost.com/wp-json/wp/v2/media/${id}`,
    method: "GET",
  }));

  React.useEffect(() => {
    if (response && response.data) {
      setPageData(response.data);
    }
    if (
      response &&
      response.error &&
      response.error.code === "rest_forbidden"
    ) {
      collectCredentials();
    }
  }, [response]);

  React.useEffect(() => {
    if (featuredMediaData.data) {
      setFeaturedMedia(featuredMediaData.data);
    }
  }, [featuredMediaData]);

  React.useEffect(() => {
    if (token && location && location.search) {
      const { id, _thumbnail_id } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      if (id) {
        fetchPost(id);
      }
      if (_thumbnail_id) {
        fetchFeaturedMedia(_thumbnail_id);
      }
    }
  }, [token, location]);

  return (
    <>
      <Layout location={location}>
        <Row>
          {pageData ? (
            <>
              <SEO title="Preview" description="Preview page" />
              <Column
                md={9}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingRight: "0px",
                }}
              >
                {featuredMedia && featuredMedia.media_details && (
                  <FeaturedImage
                    src={featuredMedia.media_details.sizes.full.source_url}
                  />
                )}
                <PageContent>
                  <>
                    <ContentHeader>
                      {ReactHtmlParser(pageData.title.rendered)}
                    </ContentHeader>
                    {pageData._embedded.author && (
                      <ArticleMeta
                        author={{
                          name: pageData._embedded.author[0].name,
                          url: pageData._embedded.author[0].slug,
                          customAvatar: null,
                        }}
                        date={pageData.date}
                        title={pageData.title.rendered}
                        slug={pageData.slug}
                        id={pageData.id}
                      />
                    )}
                    <ContentParser htmlString={pageData.content.rendered} />
                  </>
                </PageContent>
              </Column>
              <Column md={3}>
                <SideBar
                  Sharing={
                    <SharingBox
                      title={pageData.title.rendered}
                      slug={pageData.slug}
                      excerpt={pageData.excerpt.rendered}
                    />
                  }
                />
              </Column>
            </>
          ) : (
            <>
              <SEO title="404: Not found" description="Page not found" />
              <Column md={12}>
                <PageContent>
                  <>
                    {response.pending ? (
                      <h1>Preview loading...</h1>
                    ) : (
                      <h1>Preview not available</h1>
                    )}

                    {response.error && (
                      <p>Check that the URL is correct and try again.</p>
                    )}
                    {authResponse.error && (
                      <>
                        <p>Error authenticating user credentials.</p>
                        <button onClick={() => collectCredentials()}>
                          Try again
                        </button>
                      </>
                    )}
                  </>
                </PageContent>
              </Column>
            </>
          )}
        </Row>
      </Layout>
    </>
  );
};

const ContentHeader = styled.h1`
  font-family: "Bebas Neue";
`;

const FeaturedImage = styled.img`
  margin-bottom: 0;
  width: 100%;
`;
export default PreviewPage;
